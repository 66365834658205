import qs from 'qs'
import React from 'react'
import Container from 'components/Container'
import Link from 'components/Link'
import PasswordChangeForm from 'components/PasswordChangeForm'
import Text from 'components/Text'
import paths from 'constants/paths'
import statusCodes from 'constants/status-codes'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as notifications from 'libs/notifications'
import * as url from 'libs/url'
import * as userApiRequest from 'libs/user-api-request'
import Utility from 'templates/Utility'

export default function ResetPassword() {
  useEventPageVisited('Reset password')
  const [isInvalidToken, setIsInvalidToken] = React.useState(false)

  async function submit(values, formikActions) {
    const { token } = url.getQueryString()

    function handleError(message) {
      setIsInvalidToken(true)
      notifications.notifyError(message)
    }

    const response = await userApiRequest
      .updatePassword({
        newPassword: values.passwordNew,
        token,
      })
      .catch((error) => {
        console.error(error.message)
        handleError(error.message)
      })

    if (response.statusCode === statusCodes.SUCCESS) {
      notifications.notifyAndRedirect({
        //message: response.messsage,
        message: 'Password successfully updated',
        to: `${paths.SIGN_IN}?${qs.stringify({ reset: true })}`,
      })
      return
    }

    handleError(response.message)
    formikActions.setSubmitting(false)
  }

  function handleSubmit(values, formikActions) {
    submit(values, formikActions)
  }

  const linkProps = {
    decoration: 'underline',
    target: '_blank',
  }

  return (
    <Utility title="Reset password" className="ResetPassword">
      <Container flush size="small">
        {!isInvalidToken ? (
          <PasswordChangeForm
            // TODO: if Buttons 1 child, return just Button?
            //buttonProps={{ full: true }}
            buttonsProps={{ axis: 'y' }}
            oldPassword={false}
            onSubmit={handleSubmit}
          />
        ) : (
          <>
            <Text element="p">
              The reset password link you used has expired. If you recently requested a password
              reset, please check your email for a more recent link.
            </Text>
            <Text element="p">
              If you’d like to receive a new email with a link to reset your password, please{' '}
              <Link {...linkProps} to={paths.PASSWORD_FORGOT}>
                fill out this form
              </Link>
              .
            </Text>
          </>
        )}
      </Container>
    </Utility>
  )
}
