import classNames from 'classnames'
import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import Button, { ButtonProps } from 'components/Button'
import Buttons, { ButtonsProps } from 'components/Buttons'
import FieldPassword from 'components/FieldPassword'
import statusCodes from 'constants/status-codes'
import type { Component } from 'constants/types'
import * as notifications from 'libs/notifications'
import * as userApiRequest from 'libs/user-api-request'
import * as validations from 'libs/validations'

type Props = {
  buttonProps?: ButtonProps | {}
  buttonsProps?: ButtonsProps | {}
  oldPassword?: boolean
  onSubmit?: () => void
  onSuccess?: () => void
} & Component

export default function PasswordChangeForm({
  buttonProps = {},
  buttonsProps = {},
  className,
  oldPassword = true,
  onSubmit,
  onSuccess,
}: Props) {
  async function submit(values, formikActions) {
    if (onSubmit) {
      onSubmit(values, formikActions)
      return
    }

    const response = await userApiRequest.changePassword({
      newPassword: values.passwordNew,
      password: values.password,
    })

    if (response.status === statusCodes.SUCCESS) {
      notifications.notifySuccess('Password changed successfully!')
      if (onSuccess) onSuccess()
      return
    }

    notifications.notifyError()
    formikActions.setSubmitting(false)
  }

  function handleSubmit(values, formikActions) {
    submit(values, formikActions)
  }

  return (
    <Formik
      initialValues={{
        password: '',
        passwordNew: '',
        passwordNewConfirm: '',
      }}
      validationSchema={Yup.object({
        password: oldPassword ? validations.PASSWORD : undefined,
        passwordNew: validations.PASSWORD,
        passwordNewConfirm: Yup.string()
          .oneOf([Yup.ref('passwordNew'), null], validations.PASSWORDS_MATCH_MESSAGE)
          .required(validations.REQUIRED_MESSAGE),
      })}
      className={classNames('PasswordChangeForm', className)}
      onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form>
          {oldPassword && <FieldPassword label="Old password" />}
          <FieldPassword autoComplete="new-password" label="New password" name="passwordNew" />
          <FieldPassword
            autoComplete="new-password"
            label="Confirm new password"
            name="passwordNewConfirm"
          />
          <Buttons {...buttonsProps}>
            <Button
              {...buttonProps}
              disabled={formikProps.isSubmitting}
              onClick={formikProps.submitForm}>
              {buttonProps?.children || 'Submit'}
            </Button>
          </Buttons>
        </Form>
      )}
    </Formik>
  )
}
